import axiosInstance from "@/plugins/axios";

export const actions = {
    async createContentFeed(contentFeedData) {
        try {
            const response = await axiosInstance.post(`/api/content-feeds`, contentFeedData);
            return response.data.data; // Return the updated page data
        } catch (error) {
            console.error('Error updating page:', error);
            throw error;
        }
    },
};
