<template> 
    <div class="w-full p-3" :class="border"> 

        <p class="text-[#777777] text-[12px] justify-start" :class="padding">{{ title }}</p>

        <div class="flex flex-row gap-2 flex-1 items-center"> 
            <div class="rounded-full h-[30px] p-2 border-[#EAEAEA] border flex justify-center items-center" :style="{ backgroundColor }"> 

                <i class="text-[#1A534E] !text-[14px]" :class="icon"></i>
            </div>

            <div class="flex flex-col"> 
                <p class="text-[#134A45] text-[10px]">{{ header }}</p>
                <p class="text-[#8E8E8E] text-[8px]">{{ text }}</p>
                <p class="text-[#8E8E8E] text-[8px]">Odabrao si: {{ selectedOption.name }}</p>
                
            </div>
                
            <dropdown 
                v-model="selectedOption" 
                :options="[{name: 'Ukljuceno'},{name: 'Iskljuceno'}]" 
                optionLabel="name"  
                placeholder="Odaberi" 
                :pt="{trigger: '!w-[13px] !text-[1px] !p-0', input: '!p-1 !text-[9px]'}"
                class="bg-[#E4FFDD] border-[#CDF5C3] flex items-center justify-start py-0">
                
                <template #dropdownicon>
                    <i class="pr pr-arrow-bottom-87we !text-[12px]"></i>
                </template>
            </dropdown>
        </div> 
        
        
    </div>
</template>

<script setup> 

import Dropdown from "primevue/dropdown";
import {ref} from "vue";

const selectedOption = ref({name: "Echo"});

const props = defineProps({
    title: String,
    header: String,
    text: String,
    backgroundColor: {
        type: String,
        default: ['#FFF7CC']
    },
    border: {
        type: String,
        default: ['border-t']
    },

    icon:{
        type: String,
        default: "pr pr-device-message"
    },
    padding: {
        type: String,
        default: ['pb-2']
    }
})

</script>