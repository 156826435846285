<template>
  <div class="h-full border-t-[0.24px] bg-white w-[300px] max-w-[300px]">
    <header class="flex justify-start items-center gap-2.5 px-[20px] py-[7px] border-t-0 border-r-0 border-b-[0.4px] border-l-0 border-[#e6e6e6]">
      <p class="text-[14px] text-left text-[#9f9f9f]">Podcast Management</p>
    </header>
    <section class="p-4 flex flex-col gap-2 flex-grow overflow-y-auto">
      <button @click="showAddPodcastModal" class="bg-primary-500 text-white px-3 py-2 rounded">
        <span class="pr pr-add"></span> Add Podcast
      </button>
      <ul>
        <li
            v-for="podcast in podcasts"
            :key="podcast.id"
            class="flex justify-between items-center py-4 border-b w-full"
        >
          <section class="flex justify-start space-x-2">
            <img :src="podcast.image_url" :alt="podcast.title"  class="rounded-full w-10 h-10"/>
            <section class="flex flex-col">
              <span class="text-[14px] text-gray-600">{{ podcast.title }}</span>
              <span class="text-[12px] text-gray-400">{{ podcast.author.data.nickname }}</span>
            </section>
          </section>
          <div class="flex gap-1">
            <button @click="editPodcast(podcast)" class="text-blue-500 hover:text-blue-700">
              <span class="pr pr-edit !text-[14px]"></span>
            </button>
            <button @click="deletePodcast(podcast.id)" class="text-red-500 hover:text-red-700">
              <span class="pr pr-trash !text-[14px]"></span>
            </button>
            <button @click="publishPodcast(podcast.id)" class="text-green-500 hover:text-green-700">
              <span class="pr pr-send-square !text-[14px]"></span>
            </button>
          </div>
        </li>
      </ul>
    </section>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import AddPodcastModal from '@/components/app/dashboard/modals/AddPodcastModal.vue';
import { usePodcastStore } from '@/store/podcasts/index.js';
import {useModal} from "vue-final-modal";
import {useBooksStore} from "@/store/books/index.js";
import {useContentFeedStore} from "@/store/content-feed/index.js";

const isAddPodcastModalVisible = ref(false);
const podcastStore = usePodcastStore();
const contentFeedStore = useContentFeedStore()

// Fetch podcasts on component mount
onMounted(() => {
  podcastStore.fetchPodcasts();
});

// Computed property to get podcasts from the store
const podcasts = computed(() => podcastStore.podcasts);

// Function to show the add podcast modal
const showAddPodcastModal = () => {
  const { open, close } = useModal({
    component: AddPodcastModal,
    attrs: {
      data: {
      },
      onConfirm(data) {
        podcastStore.fetchPodcasts()
        close()
      }
    }
  })
  open()
};

// Handle modal confirmation (if any)
const handleAddPodcastConfirm = () => {
  // Implement confirmation logic if needed
};

// Handle after a podcast is created
const handleAddPodcastCreated = () => {
  isAddPodcastModalVisible.value = false;
  podcastStore.fetchPodcasts(); // Refresh the list
};

// Edit podcast function (implementation depends on your requirements)
const editPodcast = (podcast) => {
  // Implement edit functionality, possibly opening another modal
  alert(`Edit functionality for "${podcast.title}" is not implemented yet.`);
};

// Delete podcast function
const deletePodcast = (podcastId) => {
  if (confirm('Are you sure you want to delete this podcast?')) {
    podcastStore.deletePodcast(podcastId)
        .then(() => {
          podcastStore.fetchPodcasts(); // Refresh the list
        })
        .catch(error => {
          console.error("Error deleting podcast:", error);
          alert("Failed to delete the podcast. Please try again.");
        });
  }
};

// Delete podcast function
const publishPodcast = (podcastId) => {
  if (confirm('Are you sure you want to publish this podcast?')) {
    contentFeedStore.createContentFeed({
      title: 'Novi podcast',
      type: 'PODCAST'
    }).then((response) => {
      podcastStore.attachContentFeedToPodcast(podcastId, response.id)
    })
  }
};
</script>

<style scoped>
/* Add any specific styles if needed */
</style>
