<template>
  <div class="h-full border-t-[0.24px] bg-white w-[300px] max-w-[300px]">
    <header class="flex justify-start items-center gap-2.5 px-[20px] py-[7px] border-t-0 border-r-0 border-b-[0.4px] border-l-0 border-[#e6e6e6]">
      <p class="text-[14px] text-left text-[#9f9f9f]">Hadith Management</p>
    </header>
    <section class="h-full p-4 flex flex-col gap-2 flex-grow overflow-y-auto">
      <section class="h-14 w-full">
        <button @click="showAddHadithModal" class="w-full bg-primary-500 text-white px-3 py-2 rounded">
          <span class="pr pr-add"></span> Add Hadith
        </button>
      </section>
      <ul class="h-[calc(100%-166px)] flex flex-col gap-y-2 overflow-y-scroll">
        <li
            v-for="hadith in hadiths"
            :key="hadith.id"
            class="flex flex-col justify-between items-start border w-full rounded-xl"
        >
          <div class="flex gap-1 border-b w-full justify-between px-3 py-2">
            <button @click="editHadith(hadith)" class="text-gray-500 hover:text-gray-900">
              <span class="pr pr-arrow-left !text-[14px]"></span>
            </button>
            <section class="flex gap-1">
              <button @click="editHadith(hadith)" class="text-blue-500 hover:text-blue-700">
                <span class="pr pr-edit !text-[14px]"></span>
              </button>
              <button @click="deleteHadith(hadith.id)" class="text-red-500 hover:text-red-700">
                <span class="pr pr-trash !text-[14px]"></span>
              </button>
              <button @click="publishHadith(hadith.id)" class="text-green-500 hover:text-green-700">
                <span class="pr pr-send-square !text-[14px]"></span>
              </button>
            </section>
          </div>
          <section class="flex justify-start space-x-2 p-4">
            <section class="flex flex-col space-y-2">
              <span class="text-[14px] text-gray-500">{{ truncate(hadith.text, 150) }}</span>
              <span class="text-[12px] text-gray-400">{{ hadith.narrator.name }}</span>
            </section>
          </section>
        </li>
      </ul>
    </section>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import AddHadithModal from '@/components/app/dashboard/modals/AddHadithModal.vue';
import { useHadithStore } from '@/store/hadiths/index.js';
import {useModal} from "vue-final-modal";
import {useContentFeedStore} from "@/store/content-feed/index.js";

const isAddHadithModalVisible = ref(false);
const hadithStore = useHadithStore();
const contentFeedStore = useContentFeedStore()

// Fetch hadiths on component mount
onMounted(() => {
  hadithStore.fetchHadiths();
});

const truncate = (text, limit) => {
  if (text.length > limit) {
    for (let i = limit; i > 0; i--){
      if(text.charAt(i) === ' ' && (text.charAt(i-1) != ','||text.charAt(i-1) != '.'||text.charAt(i-1) != ';')) {
        return text.substring(0, i) + '...';
      }
    }
    return text.substring(0, limit) + '...';
  } else {
    return text;
  }
}

// Computed property to get hadiths from the store
const hadiths = computed(() => hadithStore.hadiths);

// Function to show the add hadith modal
const showAddHadithModal = () => {
  const { open, close } = useModal({
    component: AddHadithModal,
    attrs: {
      data: {
      },
      onConfirm(data) {
        hadithStore.fetchHadiths()
        close()
      }
    }
  })
  open()
};

// Handle modal confirmation (if any)
const handleAddHadithConfirm = () => {
  // Implement confirmation logic if needed
};

// Handle after a hadith is created
const handleAddHadithCreated = () => {
  isAddHadithModalVisible.value = false;
  hadithStore.fetchHadiths(); // Refresh the list
};

// Edit hadith function (implementation depends on your requirements)
const editHadith = (hadith) => {
  // Implement edit functionality, possibly opening another modal
  alert(`Edit functionality for "${hadith.title}" is not implemented yet.`);
};

// Delete hadith function
const deleteHadith = (hadithId) => {
  if (confirm('Are you sure you want to delete this hadith?')) {
    hadithStore.deleteHadith(hadithId)
        .then(() => {
          hadithStore.fetchHadiths(); // Refresh the list
        })
        .catch(error => {
          console.error("Error deleting hadith:", error);
          alert("Failed to delete the hadith. Please try again.");
        });
  }
};

// Delete hadith function
const publishHadith = (hadithId) => {
  if (confirm('Are you sure you want to publish this hadith?')) {
    contentFeedStore.createContentFeed({
      title: 'Novi hadith',
      type: 'HADITH'
    }).then((response) => {
      hadithStore.attachContentFeedToHadith(hadithId, response.id)
    })
  }
};
</script>

<style scoped>
/* Add any specific styles if needed */
</style>
