import axiosInstance from "@/plugins/axios";

export const actions = {
    togglePageSelection(pageId) {
        const index = this.selectedPages.indexOf(pageId);
        if (index > -1) {
            this.selectedPages.splice(index, 1); // Deselect the page
        } else {
            console.log('echo')
            this.selectedPages.push(pageId); // Select the page
        }
    },
    selectAllPages() {
        this.selectedPages = this.pages.map(page => page.id);
    },
    deselectAllPages() {
        this.selectedPages = [];
    },

    async fetchPages(bookId) {
        try {
            const response = await axiosInstance.get(`/api/pages?book_id=${bookId}&include=coverImage`);
            this.pages = response.data.data;
            return response.data.data; // Optionally return data for further processing
        } catch (error) {
            console.error('Error fetching pages:', error);
            throw error; // Rethrow for further handling
        }
    },

    async fetchPage(id) {
        try {
            const response = await axiosInstance.get(`/api/pages/${id}?include=coverImage`);
            this.selectedPage = response.data.data;
            return response.data.data; // Return the fetched page data
        } catch (error) {
            console.error('Error fetching page:', error);
            throw error;
        }
    },

    async attachContentFeedToPage(id, contentFeedId) {
        try {
            const response = await axiosInstance.put(`/api/pages/${id}/content-feeds/${contentFeedId}/attach`, {model_type: 'Laraplate\\Entities\\Page\\Models\\Page'});
            return response.data.data; // Return the fetched page data
        } catch (error) {
            console.error('Error fetching page:', error);
            throw error;
        }
    },

    async addPage(pageData) {
        try {
            const response = await axiosInstance.post('/api/pages', pageData);
            this.pages.push(response.data.data);
            return response.data.data;
        } catch (error) {
            console.error('Error adding page:', error);
            throw error;
        }
    },

    async attachPodcastToPage(pageId, podcastId) {
        try {
            const response = await axiosInstance.put(`/api/pages/${pageId}/podcasts/${podcastId}/attach`, []);
            this.pages.push(response.data.data);
            return response.data.data;
        } catch (error) {
            console.error('Error adding page:', error);
            throw error;
        }
    },

    async uploadFile(data, id) {
        try {
            const response = await axiosInstance.post(`/api/pages/${id}/files`, data);
            return response.data.data; // Return the added page data
        } catch (error) {
            console.error('Error adding page:', error);
            throw error;
        }
    },

    async updatePage(id, pageData) {
        try {
            const response = await axiosInstance.put(`/api/pages/${id}`, pageData);
            const index = this.pages.findIndex((page) => page.id === id);
            if (index !== -1) {
                this.pages[index] = response.data.data;
            }
            return response.data.data; // Return the updated page data
        } catch (error) {
            console.error('Error updating page:', error);
            throw error;
        }
    },

    async deletePage(id) {
        try {
            await axiosInstance.delete(`/api/pages/${id}`);
            this.pages = this.pages.filter((page) => page.id !== id);
            // Optionally return some status or confirmation
        } catch (error) {
            console.error('Error deleting page:', error);
            throw error;
        }
    },
};
