import axiosInstance from "@/plugins/axios";

export const actions = {
    async fetchHadiths() {
        try {
            const response = await axiosInstance.get('/api/hadiths?include=narrator'); // Adjust the API endpoint as needed
            this.hadiths = response.data.data;
        } catch (error) {
            console.error("Error fetching hadiths:", error);
        }
    },
    async addHadith(formData) {
        try {
            const response = await axiosInstance.post('/api/hadiths', formData);
            return response.data.data
        } catch (error) {
            console.error("Error adding hadith:", error);
            throw error; // Re-throw to handle it in the component if needed
        }
    },
    async deleteHadith(hadithId) {
        try {
            await axiosInstance.delete(`/api/hadiths/${hadithId}`);
            this.hadiths = this.hadiths.filter(hadith => hadith.id !== hadithId);
        } catch (error) {
            console.error("Error deleting hadith:", error);
            throw error; // Re-throw to handle it in the component if needed
        }
    },
    async attachContentFeedToHadith(id, contentFeedId) {
        try {
            const response = await axiosInstance.put(`/api/hadiths/${id}/content-feeds/${contentFeedId}/attach`, {model_type: 'Laraplate\\Entities\\Hadith\\Models\\Hadith'});
            return response.data.data; // Return the fetched page data
        } catch (error) {
            console.error('Error fetching page:', error);
            throw error;
        }
    },
};
