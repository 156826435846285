<template>
    <div class="bg-[#F2F8F7] w-full h-full flex flex-grow">
      <div class="h-[calc(100%-58px)] border-t-[0.24px] overflow-y-auto flex flex-grow">
        <section class="w-full flex items-start justify-center py-10">
          <section class="w-[820px] bg-white page-shadow overflow-y-visible p-10">
            <h1 class="text-3xl pb-3">{{ pagesStore.selectedPage.title }}</h1>
            <Draggable v-model="pageContentsStore.pageContents" class="flex flex-col gap-1" @end="handleDragEnd" item-key="id">
              <template #item="{element, index}">
                <component
                    :is="pageComponentsMap[element.type]"
                    :key="index"
                    :data="element.content"
                    :id="element.id"
                    @edit-block="editElement(element)"
                    @blockFocused="setActiveBlock(element)"
                />
              </template>
            </Draggable>
            <DropdownButton :items="items" @update:selected="handleSelection" />
          </section>
        </section>
      </div>
      <div class="flex">
        <component
            :is="sidebarComponentsMap[selectedSidebar]"
            :activeBlock="activeBlock"
            :editElement="editElement"
        />
        <SidebarSwitcher :options="sidebarOptions" v-model:selectedSidebar="selectedSidebar" />
      </div>
   </div>
</template>

<script setup>
import Draggable from 'vuedraggable';
import {onMounted, ref, watch} from "vue";
import DropdownButton from "@/components/input/DropdownButton.vue";
import TextBlock from "@/components/library/books/pages/TextBlock.vue";
import QuoteBlock from "@/components/library/books/pages/QuoteBlock.vue";
import ImageBlock from "@/components/library/books/pages/ImageBlock.vue";
import { useRoute } from 'vue-router';
import { useDebounceFn } from '@vueuse/core';
import {usePagesStore} from "@/store/pages/index.js";
import {useHandleSelection} from "@/components/app/dashboard/composables/useHandleSelection.js";
import {usePageContentsStore} from "@/store/page-contents/index.js";
import TypographySidebar from "@/components/app/dashboard/sidebars/TypographySidebar.vue";
import PodcastSidebar from "@/components/app/dashboard/sidebars/PodcastSidebar.vue";
import SidebarSwitcher from "@/components/app/dashboard/sidebars/SidebarSwitcher.vue";
import HadithsSidebar from "@/components/app/dashboard/sidebars/HadithsSidebar.vue";

const activeBlock = ref(null);
const value = ref(null);
const selectedSidebar = ref('typography');

const route = useRoute();
const pagesStore = usePagesStore();
const pageContentsStore = usePageContentsStore();
const { handleSelection } = useHandleSelection(pageContentsStore);

const pageComponentsMap = {
  text: TextBlock,
  quote: QuoteBlock,
  image: ImageBlock,
};

const setActiveBlock = (element) => {
  console.log('echo')
  activeBlock.value = element;
};

const fetchData = useDebounceFn(async () => {
  await pagesStore.fetchPage(route.params.pageId).then(async () => {
    await pageContentsStore.fetchPageContents(route.params.pageId)
  });
}, 200);

const editElement = useDebounceFn(async (element) => {
  await pageContentsStore.updatePageContent(element.id, element);
}, 200);


onMounted(() => {
  fetchData();
});

watch(() => route.params.pageId, () => {
  fetchData();
});

const handleDragEnd = async (event) => {
  try {
    await pageContentsStore.bulkUpdatePageContentOrder(pageContentsStore.pageContents);
  } catch (error) {
    console.error('Error handling drag end:', error);
    // Optionally, revert the order or notify the user
  }
};

const items = ref([
  { icon: 'pr pr-text-block', text: 'Text', type: 'text', component: TextBlock },
  { icon: 'pr pr-quote-up-square', text: 'Quote', type: 'quote', component: QuoteBlock },
  { icon: 'pr pr-gallery', text: 'Image', type: 'image', component: ImageBlock },
]);

// Options for the SidebarSwitcher
const sidebarOptions = [
  { name: 'typography', icon: 'pr pr-edit-2' },
  { name: 'podcasts', icon: 'pr pr-microphone' },
  { name: 'hadiths', icon: 'pr pr-message-text-45' },
];

const sidebarComponentsMap = {
  typography: TypographySidebar,
  podcasts: PodcastSidebar,
  hadiths: HadithsSidebar,
};
</script>

<style scoped>
.page-shadow {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  filter: drop-shadow(0 4px 3px rgba(0,0,0,0.1));
}

</style>
